<template>
  <v-container fluid>
    <v-row
      align="center"
      justify="center"
      class="text-center"
    >
      <h1 class="font-weight-light">
        Complete your information
      </h1>
    </v-row>

    <v-row
      align="center"
      justify="center"
      :style="style_centerblock"
      class="text-center overflow-y-auto"
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        :style="style_form"
      >
        <!-- Informations de profile utilisateur -->
        <v-card
          :dark="this.$store.state.darkTheme ? true:false"
          style="background-color:transparent"
          elevation="0"
          class="text-left my-4"
        >
          <span class="title font-weight-light">
            About you
          </span>

          <!-- Login -->
          <v-text-field
            v-model.lazy="login"
            :disabled="this.$store.state.agalan?true:false"
            :dark="this.$store.state.darkTheme ? true:false"
            :counter="16"
            :rules="[loginRules.rule1, loginRules.rule2, loginRules.rule3, ifLoginExist]"
            hint="Best pratice is last name + first letter of given name in lowercase"
            label="Login"
            :suffix="this.$store.state.agalan?'':'-ext'"
            required
          />

          <!-- Firstname -->
          <v-text-field
            v-model.lazy="firstname"
            :disabled="this.$store.state.agalan?true:false"
            :dark="this.$store.state.darkTheme ? true:false"
            :rules="firstnameRules"
            label="Firstname"
            required
          />

          <!-- Lastname -->
          <v-text-field
            v-model.lazy="lastname"
            :disabled="this.$store.state.agalan?true:false"
            :dark="this.$store.state.darkTheme ? true:false"
            :rules="lastnameRules"
            label="Lastname"
            required
          />

          <!-- E-mail institutionel -->
          <v-text-field
            v-model.lazy="email"
            :disabled="this.$store.state.agalan?true:false"
            :dark="this.$store.state.darkTheme ? true:false"
            :rules="emailRules"
            label="E-mail"
            hint="Please use an institutional email"
            required
          />

          <!-- Laboratory -->
          <v-select
            v-model.lazy="lab"
            :dark="this.$store.state.darkTheme ? true:false"
            :items="this.$store.state.agalan?l_a_lab:l_lab"
            :rules="labRules"
            label="Laboratory"
            required
          />
          <!-- cas other lab -->
          <v-text-field
            v-if="lab == 'other lab (not listed)'"
            v-model.lazy="otherlab"
            :dark="this.$store.state.darkTheme ? true:false"
            :rules="labRules"
            label="Your laboratory name"
            required
          />
        </v-card>

        <!-- Informations a propos du contrat -->
        <v-card
          :dark="this.$store.state.darkTheme ? true:false"
          style="background-color:transparent"
          elevation="0"
          class="text-left my-4"
        >
          <span class="title font-weight-light">
            About your contract
          </span>

          <!-- Contract type -->
          <v-select
            v-model.lazy="contract_type"
            :dark="this.$store.state.darkTheme ? true:false"
            :items="l_contract_type"
            :rules="[rules.required]"
            label="Contract type"
            required
          />

          <!-- Contrat permanent -->
          <v-checkbox
            v-if="this.$store.state.agalan"
            v-model="permanent"
            :dark="this.$store.state.darkTheme ? true:false"
            class="mt-4"
            label="I have a permanent contract"
          />

          <!-- Date de contrat -->
          <v-dialog
            ref="dialog"
            v-model.lazy="modal"
            :return-value.sync="contractdate"
            persistent
            max-width="300px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="contractdate"
                style="font-color:white"
                label="End of contract"
                :rules="permanent?'':[rules.required]"
                prepend-icon="mdi-calendar"
                readonly
                :disabled="permanent"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model.lazy="contractdate"
              scrollable
              :min="String(today)"
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="modal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(contractdate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-card>

        <!-- Creation d'un mot de passe -->
        <v-card
          v-if="!this.$store.state.agalan"
          :dark="this.$store.state.darkTheme ? true:false"
          style="background-color:transparent"
          elevation="0"
          class="text-left my-4"
        >
          <span class="title font-weight-light">
            Create your password
          </span>

          <!-- Choix d'un password -->
          <v-text-field
            v-model.lazy="password"
            :dark="this.$store.state.darkTheme ? true:false"
            prepend-icon="mdi-lock"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="this.$store.state.agalan?'':passwordRules"
            :type="show ? 'text' : 'password'"
            name="inputPassword"
            label="Password"
            hint="At least 8 characters with upper case, numeric character and special character"
            counter
            class="input-group--focused mt-6"
            @click:append="show = !show"
          />

          <!-- Confirmation du password -->
          <v-text-field
            v-model.lazy="passwordCheck"
            :dark="this.$store.state.darkTheme ? true:false"
            prepend-icon="mdi-lock"
            :append-icon="showCheck ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="this.$store.state.agalan?'':[rules.required, passCheckRules]"
            :type="showCheck ? 'text' : 'password'"
            name="confirmPassword"
            label="Confirm password"
            hint="At least 8 characters"
            counter
            class="input-group--focused mb-6"
            @click:append="showCheck = !showCheck"
          />
        </v-card>

        <v-card-actions class="my-4">
          <v-btn
            :dark="this.$store.state.darkTheme ? true:false"
            text
            x-large
            color="gred"
            @click="reset"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            :dark="this.$store.state.darkTheme ? true:false"
            :disabled="!valid"
            text
            x-large
            color="gmiddleblue"
            @click="validate"
          >
            Register
          </v-btn>
        </v-card-actions>

        <v-card-actions class="my-4">
          <v-spacer />
          <v-btn
            :dark="this.$store.state.darkTheme ? true:false"
            :disabled="!completedform"
            large
            color="green"
            to="/create-account/check"
          >
            Continue
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-row>

    <v-btn
      text
      color="white"
      x-large
      exact
      absolute
      bottom
      left
      class="my-2 mx-2"
      height="70px"
      href="https://gricad.gricad-pages.univ-grenoble-alpes.fr/web/publicweb/index.html"
      target="_blank"
    >
      <!-- :style="this.$store.state.darkTheme?'filter: invert(1)':'filter: invert(0)'" -->
      <v-img
        v-if="this.$store.state.darkTheme"
        src="@/assets/logo_gricad_horizontal_dark.png"
        max-width="230px"
      />
      <v-img
        v-else
        src="@/assets/logo_gricad_horizontal.png"
        max-width="230px"
      />
    </v-btn>
  </v-container>
</template>

<script>
  import config from '@/config'
  import axios from 'axios'

  export default {
    data: () => ({
      // Couleur
      red: config.colors.gricad_red_dark1,
      card_bg_dark: config.colors.card_bg_dark,
      card_bg_light: config.colors.card_bg_light,
      barchart_color: config.colors.gricad_blue_dark3,
      blue: config.colors.gricad_oceanblue,

      // Formulaire
      valid: true,
      completedform: false,
      // Donnees du formulaire
      login: null,
      loginCheck: 'test',
      firstname: null,
      lastname: null,
      lab: null,
      otherlab: null,
      email: null,
      contract_type: null,
      permanent: false,

      today: new Date().toISOString().substr(0, 10),
      contractdate: null,
      modal: false,

      password: '',
      show: false,
      passwordCheck: '',
      showCheck: false,
      allLogins: [],

      l_lab: [],
      l_a_lab: [],

      l_contract_type: [
        '',
        'ETUDIANT',
        'DOCTORANT',
        'ITRF',
        'CHERCHEUR',
        'PRIVE',
        'TRAINER',
        'STAGIAIRE',
      ],

      // Regles
      loginRules: {
        rule1: v => !!v || 'A login is required',
        rule2: v => (v && v.length <= 16) || 'Your login must be less than 16 characters',
        rule3: v => !(v || '').match(/[^a-z0-9]/) || "Please, don't use special, uppercase or accent characteres",
      },
      firstnameRules: [
        v => !!v || 'Firstname is required',
      ],
      lastnameRules: [
        v => !!v || 'Lastname is required',
      ],
      labRules: [
        v => !!v || 'Laboratory is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        // v => /.+@.+\..+/.test(v) || 'E-mail must be valid', // old regex
        v => (/^\S[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,4}\S$/).test(v) || 'E-mail must be valid',
      ],
      passRules: {
        required: v => !!v || 'Password is required',
        min: v => v.length >= 8 || 'Min 8 characters',
      },
      passwordRules: [
        v => !!v || 'Password is required',
        v => v.length >= 8 || 'Min 8 characters',
        v => (v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) || 'Password must contain an upper case letter, a numeric character, and a special character',
        v => (v && v.length <= 40) || 'Your password must be less than 40 characters',
      ],

      rules: { // autre definition de regles (plus propre ?)
        email: v => (v || '').match(/@/) || 'Please enter a valid email',
        length: len => v => (v || '').length >= len || `Invalid character length, required ${len}`,
        password: v => (v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) ||
          'Password must contain an upper case letter, a numeric character, and a special character',
        required: v => !!v || 'This field is required',
      },

    }),
    computed: {
      ifLoginExist () {
        /* // TODO : requete axios pour tester l'existence du login
        var boolean = true
        return () => (boolean) || 'This login already exist'
        */
        if (this.$store.state.agalan) {
          return () => (!this.allLogins.includes(this.login)) || 'This login already exist'
        }
        else {
          return () => (!this.allLogins.includes(this.login + '-ext')) || 'This login already exist'
        }
      },
      passCheckRules () {
        return () => (this.password === this.passwordCheck) || 'Password does not match'
      },
      style_centerblock () {
        return {
          height: `${this.$store.state.windowH - 200}px`,
          'background-color': 'transparent',
        }
      },
      style_form () {
        return {
          width: `${this.$store.state.windowW / 2}px`,
          'background-color': 'transparent',
        }
      },
      style_bigbtn () {
        return {
          height: `${this.$store.state.windowH / 4}px`,
          width: `${this.$store.state.windowW / 4}px`,
          'background-color': this.blue,
        }
      },
    },

    // created : lance a la creation de la page
    created () {
      // Initialise la liste des labos
      axios
        .get('/getall-labs')
        .then(response => {
          var listlab = response.data
          for (var i = 0; i < listlab.length; i++) {
            var labo = listlab[i].slice(2)
            this.l_lab.push(labo)
            this.l_a_lab.push(labo)
          }

          this.l_lab.push('other lab (not listed)')
        })
      axios.get('/getall-user').then(response => {
        this.allLogins = response.data
      })

      // initialiser les v-model
      this.login = this.$store.state.registrationForm.login
      this.firstname = this.$store.state.registrationForm.firstname.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      this.lastname = this.$store.state.registrationForm.lastname.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      this.lab = this.$store.state.registrationForm.lab
      this.otherlab = this.$store.state.registrationForm.otherlab
      this.contract_type = this.$store.state.registrationForm.contracttype
      this.contractdate = this.$store.state.registrationForm.contractdate
      this.email = this.$store.state.registrationForm.email
      this.password = this.$store.state.registrationForm.password
      this.passwordCheck = this.$store.state.registrationForm.passwordcheck

      // Les donnees pour un AGALAN
    },

    methods: {
      validate () { // Action declenchee par le bouton "Register"
        if (this.$refs.form.validate()) {
          this.completedform = true
          // > creer une charterDate horodatee au moment du click sur "Register"
          // Enregistrer les informations rentrees
          this.$store.state.registrationForm.login = this.login
          if(!this.$store.state.agalan) {
            this.$store.state.registrationForm.login += '-ext'
          }
          this.$store.state.registrationForm.firstname = this.firstname.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          this.$store.state.registrationForm.lastname = this.lastname.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          this.$store.state.registrationForm.lab = this.lab
          this.$store.state.registrationForm.otherlab = this.otherlab
          this.$store.state.registrationForm.contracttype = this.contract_type
          this.$store.state.registrationForm.permanent = this.permanent
          if (this.permanent) {
            this.$store.state.registrationForm.contractdate = '0'
          } else {
            this.$store.state.registrationForm.contractdate = this.contractdate.split('-')[2] + '/' + this.contractdate.split('-')[1] + '/' + this.contractdate.split('-')[0]
          }
          var date = new Date(this.contractdate)
          this.$store.state.registrationForm.contractdate_ts = date.getTime() / 1000
          this.$store.state.registrationForm.email = this.email
          this.$store.state.registrationForm.password = this.password
          this.$store.state.registrationForm.passwordcheck = this.passwordCheck
        }
      },
      reset () {
        if (this.$store.state.agalan) {
          this.lab = null
          this.otherlab = null
          this.contract_type = null
          this.contractdate = null
          this.permanent = false
        } else {
          this.$refs.form.reset()
        }
        this.completedform = false
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },

  }
</script>

<style lang="scss">

</style>
